import { REFACTOR_URL } from '../../../helpers/env'
import { API_URL } from '../constants'
import { AlertFilter, AlertType } from '../reducers'

export const alertsByPageService = async (
  token: string,
  size: number,
  page: number,
  alertType: AlertType,
  search: string,
  filterState: AlertFilter,
  gammeIds: string[],
  makerIds: number[],
  centerIds: number[],
  sort: { field: string; sort: string } | null,
  patientId?: number,
) => {
  const getAlertProps = () => {
    switch (filterState) {
      case 'ALL':
        return ''
      case 'NOT_VIEWED':
        return '&isViewed=false&isChecked=false'
      case 'VIEWED':
        return '&isViewed=true&isChecked=false'
      case 'CHECKED':
        return '&isViewed=true&isChecked=true'
    }
  }

  const ENDPOINT: RequestInfo = `${REFACTOR_URL}/alerts?page=${page}&limit=${size}&alertType=${alertType}${getAlertProps()}&search=${search}${gammeIds
    .map((id) => '&prosthesisTypes[]=' + id)
    .join('')}${makerIds.map((id) => '&makerIds[]=' + id).join('')}${centerIds
    .map((id) => `&centerIds[]=` + id)
    .join(
      '',
    )}${sort ? `&sort=${sort.field}&order=${sort.sort}` : ''}${patientId ? `&patientId=${patientId}` : ''}`

  const parameters: RequestInit = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Host: `${API_URL}`,
      Authorization: `Bearer ${token}`,
    },
    mode: 'cors',
  }

  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      return response.json()
    })
    .then((data) => {
      return { data, status }
    })
    .catch((e) => {
      console.log(e)
    })
}
export const getAlertCount = (
  token: string,
  alertType: AlertType,
  search: string,
  filterState: AlertFilter,
  gammeIds: string[],
  makerIds: number[],
  centerIds: number[],
  patientId: number | null,
) => {
  const getAlertProps = () => {
    switch (filterState) {
      case 'ALL':
        return ''
      case 'NOT_VIEWED':
        return '&isViewed=false&isChecked=false'
      case 'VIEWED':
        return '&isViewed=true&isChecked=false'
      case 'CHECKED':
        return '&isViewed=true&isChecked=true'
    }
  }

  const ENDPOINT: RequestInfo = `${REFACTOR_URL}/alerts/alerts-count?alertType=${alertType}${getAlertProps()}&search=${search}${gammeIds
    .map((id) => '&prosthesisTypes[]=' + id)
    .join('')}${makerIds.map((id) => '&makerIds[]=' + id).join('')}${centerIds
    .map((id) => `&centerIds[]=` + id)
    .join('')}${patientId ? `&patientId=${patientId}` : ''}`

  const parameters: RequestInit = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Host: `${API_URL}`,
      Authorization: `Bearer ${token}`,
    },
    mode: 'cors',
  }

  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      return response.json()
    })
    .then((data) => {
      const obj = {
        count: data,
        alertType,
      }
      return { data: obj, status }
    })
    .catch((e) => {
      console.log(e)
    })
}

export const getAlertsSummary = (token: string) => {
  const ENDPOINT: RequestInfo = `${REFACTOR_URL}/alerts/summary`

  const parameters: RequestInit = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Host: `${API_URL}`,
      Authorization: `Bearer ${token}`,
    },
    mode: 'cors',
  }

  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      return response.json()
    })
    .then((data) => {
      return { data, status }
    })
    .catch((e) => {
      console.log(e)
    })
}

export const alertsHistoricService = (
  token: string,
  size: number,
  page: number,
) => {
  const ENDPOINT: RequestInfo = `${API_URL}/patient/export/historic?page=${page}&size=${size}`

  const parameters: RequestInit = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Host: `${API_URL}`,
      Authorization: `Bearer ${token}`,
    },
    mode: 'cors',
  }

  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      return response.json()
    })
    .then((data) => {
      return { data, status }
    })
    .catch((e) => {
      console.log(e)
    })
}

export const transmissionService = (
  token: string,
  size: number,
  page: number,
) => {
  const ENDPOINT: RequestInfo = `${API_URL}/patient/export/transmissions?page=${page}&size=${size}`

  const parameters: RequestInit = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Host: `${API_URL}`,
      Authorization: `Bearer ${token}`,
    },
    mode: 'cors',
  }

  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      return response.json()
    })
    .then((data) => {
      return { data, status }
    })
    .catch((e) => {
      console.log(e)
    })
}

export const patientExportAction = (
  token: string,
  exportAction: 'VIEW' | 'COMMENT' | 'CHECK',
  patientExportId: number,
  userId: number,
  exportActionComment?: string,
) => {
  const ENDPOINT: RequestInfo = `${API_URL}/patient/export/${patientExportId}/action`

  const body = () => {
    if (exportAction === 'VIEW')
      return JSON.stringify({ exportAction, patientExportId, userId })
    if (exportAction === 'COMMENT')
      return JSON.stringify({
        exportAction,
        exportActionComment,
        patientExportId,
        userId,
      })
    if (exportAction === 'CHECK')
      return JSON.stringify({ exportAction, patientExportId })
  }

  const parameters: RequestInit = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Host: `${API_URL}`,
      Authorization: `Bearer ${token}`,
    },
    mode: 'cors',
    body: body(),
  }

  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      return response.json()
    })
    .then((data) => {
      return { data, status }
    })
    .catch((e) => {
      console.log(e)
    })
}

export const getPDF = (token: string, exportId: number, reportId: number) => {
  const ENDPOINT: RequestInfo = `${API_URL}/patient/export/${exportId}/record/${reportId}`

  const parameters: RequestInit = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Host: `${API_URL}`,
      Authorization: `Bearer ${token}`,
    },
    mode: 'cors',
  }

  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      return response.blob()
    })
    .then((data) => {
      return { data, status }
    })
    .catch((e) => {
      console.log(e)
    })
}

export const setPDFViewed = (
  token: string,
  exportId: number,
  reportId: number,
) => {
  const ENDPOINT: RequestInfo = `${API_URL}/patient/export/${exportId}/record/${reportId}/viewed`

  const parameters: RequestInit = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Host: `${API_URL}`,
      Authorization: `Bearer ${token}`,
    },
    mode: 'cors',
  }

  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      return response.json()
    })
    .then((data) => {
      return { data, status }
    })
    .catch((e) => {
      console.log(e)
    })
}

export const getAlertActions = (token: string, alertId: number) => {
  const ENDPOINT: RequestInfo = `${REFACTOR_URL}/alerts/${alertId}/actions`

  const parameters: RequestInit = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    mode: 'cors',
  }

  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      return response.json()
    })
    .then((data) => {
      return { data, status }
    })
    .catch((e) => {
      console.log(e)
    })
}
