import { produce } from 'immer'
import { AnyAction } from '@reduxjs/toolkit'
import {
  TOGGLE_ALERT_SUCCESS,
  TOGGLE_ALERT_FAILED,
  TOGGLE_TRANSMISSION_SUCCESS,
  TOGGLE_TRANSMISSION_FAILED,
  TOGGLE_ALERT_HISTORIC_SUCCESS,
  TOGGLE_ALERT_HISTORIC_FAILED,
  TOGGLE_ALERT_USER_SETTINGS_SUCCESS,
  GET_ALERTS_SUMMARY_SUCCESS,
  GET_ALERT_ACTIONS_SUCCESS,
  GET_ALERT_COUNT_SUCCESS,
  SET_ALERT_PATIENT,
} from '../constants'

export interface IResponseStatus {
  message: string
  error: string
  data: any
  status: number
}

export interface IUserSettingsAlert {
  columns: IAlterColumn[]
  rowPerPage: number
}

export interface IAlterColumn {
  id: number
  width?: number
  visible: boolean
  order?: number
}

export interface IStateAlertPatient {
  alerts: IAlertPatient[]
  totalPages: number
  summary: {
    nbArchived: number
    nbNewAlerts: number
    nbNewTransmissions: number
  }
  alertsHistoric: IAlertPatient[]
  transmission: IAlertPatient[]
  userSettings: IUserSettingsAlert
  alertActions: IAlertAction[]
}

export interface IAlertPatient {
  id: number
  createdAt: string
  patientId: number
  patientLastName: string
  patientFirstName: string
  exportStatus: string
  exportDate: string
  alertShort: string
  fileName: string
  filePath: string
  prosthesisGamme: string
  companyName: string
  isViewed: boolean
  checked: boolean
}

export interface IAlertAction {
  action: 'VIEW' | 'COMMENT' | 'CHECK'
  actionComment: string
  createdAt: string
  id: number
  user: {
    firstName: string
    userName: string
  }
}

export type AlertFilter = 'ALL' | 'NOT_VIEWED' | 'VIEWED' | 'CHECKED'

export type AlertType =
  | 'ALERTS'
  | 'TRANSMISSIONS'
  | 'HISTORY'
  | 'CHECKED'
  | 'DISCONNECTED_DEVICES' //| 'ARCHIVED'

const initState = {
  alerts: [],
  totalPages: 0,
  summary: {
    nbArchived: 0,
    nbNewAlerts: 0,
    nbNewTransmissions: 0,
    nbDisconnected: 0,
  },
  alertsHistoric: [],
  transmission: [],
  userSettings: {
    columns: [],
    rowPerPage: 20,
  },
  alertActions: [],
} as IStateAlertPatient

const alertsReducer = produce((draft = initState, action: AnyAction) => {
  switch (action.type) {
    case TOGGLE_ALERT_SUCCESS:
      draft.alerts = action.payload.data.data
      draft.totalPages = action.payload.data.totalPages
      break
    case GET_ALERTS_SUMMARY_SUCCESS:
      draft.summary = action.payload.data
      break
    case GET_ALERT_ACTIONS_SUCCESS:
      draft.alertActions = action.payload.data
      break
    case TOGGLE_ALERT_FAILED:
      // draft = initStateAlertsPatient
      break
    case TOGGLE_ALERT_HISTORIC_SUCCESS:
      draft.alertsHistoric = action.payload.data
      break
    case TOGGLE_ALERT_HISTORIC_FAILED:
      // draft = initStateAlertsPatient
      break
    case TOGGLE_TRANSMISSION_SUCCESS:
      draft.transmission = action.payload.data
      break
    case TOGGLE_TRANSMISSION_FAILED:
      // draft = initStateAlertsPatient
      break
    case TOGGLE_ALERT_USER_SETTINGS_SUCCESS:
      draft.userSettings = {
        columns: [
          {
            id: 0,
            width: 200,
            visible: true,
            order: 0,
          },
          {
            id: 0,
            width: 200,
            visible: true,
            order: 0,
          },
          {
            id: 0,
            width: 200,
            visible: true,
            order: 0,
          },
          {
            id: 0,
            width: 200,
            visible: true,
            order: 0,
          },
          {
            id: 0,
            width: 200,
            visible: true,
            order: 0,
          },
          {
            id: 0,
            width: 200,
            visible: true,
            order: 0,
          },
        ],
        rowPerPage: 20,
      }

      break
    case GET_ALERT_COUNT_SUCCESS:
      switch (action.payload.data.alertType) {
        case 'ALERTS':
          draft.summary = {
            ...draft.summary,
            nbNewAlerts: action.payload.data.count,
          }
          break
        case 'TRANSMISSIONS':
          draft.summary = {
            ...draft.summary,
            nbNewTransmissions: action.payload.data.count,
          }
          break
        case 'HISTORY':
          draft.summary = {
            ...draft.summary,
            nbArchived: action.payload.data.count,
          }
          break
      }
      break
    case SET_ALERT_PATIENT:
      console.log({ action: action })
      draft.patientId = action.patientId
      break
    default:
      return draft
  }
})

export default alertsReducer
