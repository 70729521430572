import { produce } from 'immer'
import { AnyAction } from '@reduxjs/toolkit'
import {
  GET_USER_SUCCESS,
  SET_IS_LOGGED_FALSE,
  SET_IS_LOGGED_TRUE,
  TOGGLE_LOGIN_SUCCESS,
  TOGGLE_LOGOUT,
  TOKEN_IS_VALID_FAILED,
} from '../constants'
import { EtatFacture } from '../../billing/reducers'

export interface IAuthState {
  isLogged: Boolean
  user: IUserState | null
  company: ICompanyState | null
  doctor: IDoctor | null
  token: string | null
}

export interface IBillingAuth {
  accessToken: string
  refreshToken: string
  idToken: string
}

export interface IUserState {
  id: number
  mail: string
  pwd: string
  userName: string
  firstName: string
  compId: number
  phone: string
  title: string
  roles: [
    {
      id: number
      roleName: string
      description: string
    },
  ]
  addresses: []
}

export interface IResponseStatus {
  message: string
  error: string
  user?: IUserState
  data: any
  status: number
}

export interface ICompanyState {
  id: number
  companyName: string
  address: string
  zipCode: string
  city: string
  countryId: number
  phone: string
  type: string
  logoFileId: null
}

export interface IDoctor {
  id: number
  rppsNumber: string
  cardioImplant: boolean
  billing: boolean
  doctorAttent: boolean
  cardioAttent: boolean
}

const initState = {
  isLogged: false,
  company: null,
  doctor: null,
  user: null,
  token: null,
  billingAuth: null,
} as IAuthState

const authReducer = produce((draft = initState, action: AnyAction) => {
  switch (action.type) {
    case TOGGLE_LOGIN_SUCCESS:
      draft.user = action.payload.data.user
      draft.company = action.payload.data.user.company
      draft.doctor = action.payload.data.user.doctor
      draft.token = action.payload.data.token
      draft.isLogged = true
      break
    case GET_USER_SUCCESS:
      draft.user = {
        id: action.payload.data.id,
        name: action.payload.data.name,
        firstname: action.payload.data.first_name,
        email: action.payload.data.email,
        phone: action.payload.data.phone_number,
        verified_phone_number: action.payload.data.verified_phone_number,
        companyId: action.payload.data.society_id,
      }
      break
    case TOGGLE_LOGOUT:
      draft.user = null
      draft.company = null
      draft.doctor = null
      draft.token = null
      draft.isLogged = false
      break
    case SET_IS_LOGGED_TRUE:
      draft.isLogged = true
      break
    case SET_IS_LOGGED_FALSE:
      draft.isLogged = false
      break
    case TOKEN_IS_VALID_FAILED:
      draft.isLogged = false
      draft.user = null
      break
    default:
      return draft
  }
})

export interface ILastBilling {
  patient_id: bigint
  billEnd: Date
  billing_date: Date
  billing_detail: {
    etat_facture: EtatFacture
  }
  billStart: Date
}
export default authReducer
