import {
  Close as CloseIcon,
  Save as SaveIcon,
  // Delete as DeleteIcon
} from '@mui/icons-material'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Box,
  Button,
  NativeSelect,
  FormControlLabel,
  Checkbox,
  TextField,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import Loader from '../../../common/Loader'
import {
  IAffectTransmitter,
  IImplantationList,
  ITransmitterReference,
} from '../../reducers'
import dateformat from 'dateformat'
import { transmitterSchema } from '../../../../schemas'
import { useSelector } from 'react-redux'
import { RootState } from 'src/store'

interface IProps {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  selectedImplantation: IImplantationList
  getTransmitterReferences: () => Promise<unknown>
  transmitterReferences: ITransmitterReference[]
  createTransmitter: (
    referenceId: number,
    serialNumber: string,
    method: 'POST' | 'PATCH',
    transmitterId?: number,
  ) => Promise<number>
  affectTransmitter: (
    infos: IAffectTransmitter,
    method: 'POST' | 'PATCH',
  ) => Promise<unknown>
  postAgreement: (
    transmitterId: number,
    file: string | ArrayBuffer | null,
  ) => Promise<unknown>
  deleteTransmitter: (
    transmitterId: number,
    affectationId: number,
  ) => Promise<unknown>
  patientId: string
}

const ModalModifyTransmitter = (props: IProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const [modalDeleteOpen, setModalDeleteOpen] = useState(false)
  const [deliveredByCenter, setDeliveredByCenter] = useState(false)
  const [agreement, setAgreement] = useState(false)
  const [file, setFile] = useState<File | null>(null) //eslint-disable-line
  const [references, setReferences] = useState<ITransmitterReference[]>([])
  const [selectedReferenceId, setSelectedReferenceId] =
    useState<string>('not-defined')
  const [serialNumber, setSerialNumber] = useState('')
  const [deliveryDate, setDeliveryDate] = useState('')
  const [firstTransmissionDate, setFirstTransmissionDate] = useState('')
  const [deliveryNote, setDeliveryNote] = useState('')

  const centerId = useSelector(
    (state: RootState) => state.authReducer?.company?.id,
  )

  const RequiredField = () => {
    return <span style={{ color: 'red', fontWeight: 'bold' }}>*</span>
  }

  useEffect(() => {
    fetchDisplay()
  }, []) //eslint-disable-line

  useEffect(() => {
    if (props.transmitterReferences)
      setReferences(
        props.transmitterReferences.filter(
          (ref) =>
            ref.makerId ===
            props.selectedImplantation.prosthesisObject?.reference?.maker
              ?.company?.id,
        ),
      )
  }, [props.transmitterReferences, props.selectedImplantation]) //eslint-disable-line

  useEffect(() => {
    if (props.selectedImplantation.transmitterAffectation) {
      setDeliveredByCenter(
        props.selectedImplantation.transmitterAffectation.delivered_by_center,
      )
      setAgreement(props.selectedImplantation.transmitterAffectation.agreement)
      setSelectedReferenceId(
        props.selectedImplantation.transmitterAffectation.transmitter?.reference_id?.toString() ||
          'not-defined',
      )
      setSerialNumber(
        props.selectedImplantation.transmitterAffectation.transmitter
          ?.serial_number || '',
      )
      setDeliveryDate(
        props.selectedImplantation.transmitterAffectation.delivery_date
          ? dateformat(
              props.selectedImplantation.transmitterAffectation.delivery_date,
              'yyyy-mm-dd',
            )
          : '',
      )
      setFirstTransmissionDate(
        props.selectedImplantation.transmitterAffectation
          .first_transmission_date
          ? dateformat(
              props.selectedImplantation.transmitterAffectation
                .first_transmission_date,
              'yyyy-mm-dd',
            )
          : '',
      )
      setDeliveryNote(
        props.selectedImplantation.transmitterAffectation.delivery_note || '',
      )
    } else {
      setSelectedReferenceId('not-defined')
      setSerialNumber('')
      setDeliveryDate('')
      setFirstTransmissionDate('')
      setDeliveryNote('')
    }
  }, [props.selectedImplantation]) //eslint-disable-line

  const fetchDisplay = async () => {
    setIsLoading(true)
    try {
      await props.getTransmitterReferences()
    } catch {
      toast.error('Erreur lors du chargement des références de transmetteurs')
    } finally {
      setIsLoading(false)
    }
  }

  // const toBase64 = (file: File) => new Promise((resolve: (value: string | ArrayBuffer | null) => void, reject) => {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     reader.onload = () => resolve(reader.result);
  //     reader.onerror = reject;
  // });

  const handleSubmit = async () => {
    setIsLoading(true)
    console.log({ impl: props.selectedImplantation })

    try {
      let transmitterId = 0
      await transmitterSchema.validate({
        selectedReferenceId,
        serialNumber,
        deliveryDate,
        centerId,
      })
      transmitterId = await props.createTransmitter(
        parseInt(selectedReferenceId),
        serialNumber,
        props.selectedImplantation.transmitterAffectation ? 'PATCH' : 'POST',
        props.selectedImplantation.transmitterAffectation?.transmitter_id,
      )
      const infos: IAffectTransmitter = {
        agreement,
        centerId,
        deliveredByCenter,
        deliveryDate: deliveryDate ? new Date(deliveryDate).toISOString() : '',
        deliveryNote,
        firstTransmissionDate: firstTransmissionDate
          ? new Date(firstTransmissionDate).toISOString()
          : '',
        patientId: props.patientId,
        prosthesisId: props.selectedImplantation.prosthesisObject.id,
        transmitterId: props.selectedImplantation.transmitterAffectation
          ?.transmitter_id
          ? props.selectedImplantation.transmitterAffectation.transmitter_id
          : transmitterId,
      }

      await props.affectTransmitter(
        infos,
        props.selectedImplantation.transmitterAffectation ? 'PATCH' : 'POST',
      )
      // if (file) await props.postAgreement(transmitterId, await toBase64(file))
      toast.success('Transmetteur modifié')
      props.setIsOpen(false)
    } catch (err: any) {
      toast.error(
        err.errors[0]
          ? err.errors[0]
          : "Erreur lors de l'enregistrement des modifications",
      )
    } finally {
      setIsLoading(false)
    }
  }

  const handleDelete = async () => {
    setIsLoading(true)
    try {
      // await props.deleteTransmitter(props.selectedImplantation.transmitterAffectation.transmitter_id)
      toast.success('Transmetteur supprimé')
      setModalDeleteOpen(false)
      props.setIsOpen(false)
    } catch {
      toast.error('Erreur lors de la suppression du transmetteur')
    } finally {
      setIsLoading(false)
    }
  }

  const ModalDelete = () => {
    return (
      <Dialog
        fullWidth={true}
        maxWidth={'xs'}
        open={modalDeleteOpen}
        onClose={() => setModalDeleteOpen(false)}
        aria-labelledby="responsive-dialog-title"
        sx={{ padding: '30px auto' }}
        PaperProps={{
          style: { borderRadius: 20 },
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <DialogTitle
            id="responsive-dialog-title"
            sx={{
              display: 'flex',
              position: 'relative',
              justifyContent: 'center',
              background: 'linear-gradient(60deg, #84c2ce, #96dcea)',
              color: 'white',
              fontWeight: '700',
              fontSize: '24px',
            }}
          >
            {'Confirmer'}
            <IconButton
              sx={{ position: 'absolute', right: '20px' }}
              onClick={() => setModalDeleteOpen(false)}
            >
              <CloseIcon sx={{ color: 'white' }} />
            </IconButton>
          </DialogTitle>
          <DialogContent
            sx={{
              display: 'flex',
              alignItems: 'start',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <div style={{ paddingTop: '20px' }}>
              Êtes-vous sûr de vouloir supprimer le transmetteur ?
            </div>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'end',
                width: '100%',
                marginTop: '30px',
                height: '30px',
              }}
            >
              <Button
                className="group-button"
                onClick={() => setModalDeleteOpen(false)}
              >
                Retour
              </Button>
              <Button className="group-button-success" onClick={handleDelete}>
                Confirmer
              </Button>
            </Box>
          </DialogContent>
        </div>
      </Dialog>
    )
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={props.isOpen}
      onClose={() => props.setIsOpen(false)}
      aria-labelledby="responsive-dialog-title"
      sx={{ padding: '30px auto' }}
      PaperProps={{
        style: { borderRadius: 20 },
      }}
    >
      <Loader text="Enregistrement des modifications" open={isLoading} />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <DialogTitle
          id="responsive-dialog-title"
          sx={{
            display: 'flex',
            position: 'relative',
            justifyContent: 'center',
            background: 'linear-gradient(60deg, #84c2ce, #96dcea)',
            color: 'white',
            fontWeight: '700',
            fontSize: '24px',
          }}
        >
          Modifier le transmetteur
          <IconButton
            sx={{ position: 'absolute', right: '20px' }}
            onClick={() => props.setIsOpen(false)}
          >
            <CloseIcon sx={{ color: 'white' }} />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            display: 'flex',
            alignItems: 'start',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <div
            style={{ marginTop: '20px', display: 'flex', alignItems: 'center' }}
          >
            <span style={{ whiteSpace: 'nowrap', marginRight: '10px' }}>
              Mode de remise :
            </span>
            <NativeSelect
              value={deliveredByCenter ? 'true' : 'false'}
              onChange={(event) =>
                setDeliveredByCenter(event.target.value === 'true')
              }
            >
              <option value="true">Remis par le centre</option>
              <option value="false">À livrer chez le patient</option>
            </NativeSelect>
          </div>
          <div
            style={{ marginTop: '20px', display: 'flex', alignItems: 'center' }}
          >
            <span style={{ whiteSpace: 'nowrap', marginRight: '10px' }}>
              Consentement signé :
            </span>
            <FormControlLabel
              onChange={() => setAgreement(!agreement)}
              checked={agreement}
              control={
                <Checkbox
                  sx={{ color: agreement ? '#84c2ce !important' : '' }}
                />
              }
              label=""
            />
          </div>
          {/* <div style={{marginTop: '20px', display: 'flex', alignItems: 'center'}}>
                    <span style={{whiteSpace: 'nowrap', marginRight: '10px'}}>Consentement :</span>
                    <input type="file" style={{fontSize: '14px'}} onChange={(e) => setFile(e.target.files ? e.target.files[0] : null)} accept="application/pdf,.doc,.docx,.xls,.png,.jpg,.jpeg" />
                </div> */}
          <div
            style={{ marginTop: '20px', display: 'flex', alignItems: 'center' }}
          >
            <span style={{ whiteSpace: 'nowrap', marginRight: '10px' }}>
              Référence du transmetteur :
            </span>
            <NativeSelect
              value={selectedReferenceId}
              onChange={(event) => setSelectedReferenceId(event.target.value)}
            >
              {references.map((ref, index) => (
                <option key={'transref' + index} value={ref.id.toString()}>
                  {ref.referenceName}
                </option>
              ))}
            </NativeSelect>
          </div>
          <div
            style={{
              marginTop: '20px',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <span>
              Numéro de série <RequiredField /> :
            </span>
            <TextField
              sx={{ marginLeft: '10px', width: '50%' }}
              value={serialNumber}
              variant="standard"
              onChange={(e) => setSerialNumber(e.target.value)}
            />
          </div>
          <div
            style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}
          >
            <span style={{ whiteSpace: 'nowrap', marginRight: '10px' }}>
              Date de remise <RequiredField /> :
            </span>
            <TextField
              margin="dense"
              type="date"
              variant="standard"
              value={deliveryDate}
              onChange={(event) => setDeliveryDate(event.target.value)}
            />
          </div>
          <div
            style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}
          >
            <span style={{ whiteSpace: 'nowrap', marginRight: '10px' }}>
              Date de première transmission :
            </span>
            <TextField
              margin="dense"
              type="date"
              variant="standard"
              value={firstTransmissionDate}
              onChange={(event) => setFirstTransmissionDate(event.target.value)}
            />
          </div>
          <Box
            sx={{
              marginTop: '30px',
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <span>Commentaires :</span>
            <textarea
              style={{
                marginTop: '10px',
                border: '1px solid #84c2ce',
                borderRadius: '8px',
                padding: '10px',
                fontFamily: 'Roboto',
                fontSize: '14px',
              }}
              value={deliveryNote}
              onChange={(e) => setDeliveryNote(e.target.value)}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'end',
              width: '100%',
              marginTop: '20px',
            }}
          >
            {/* <IconButton onClick={() => setModalDeleteOpen(true)}>
                        <DeleteIcon />
                    </IconButton> */}
            <IconButton onClick={handleSubmit}>
              <SaveIcon />
            </IconButton>
          </Box>
        </DialogContent>
      </div>
      <ModalDelete />
    </Dialog>
  )
}

export default ModalModifyTransmitter
