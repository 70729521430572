import { Box, Tab, Tabs } from '@mui/material'
import {
  DataGridPro,
  GridColumns,
  GridRenderCellParams,
  GridSortModel,
  GridValueGetterParams,
} from '@mui/x-data-grid-pro'
import moment from 'moment'
import { useEffect, useRef, useState } from 'react'
import { BsFillCalendarEventFill } from 'react-icons/bs'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import PaginationComponent from '../../../modules/common/PaginationComponent'
import history from '../../../routes/history'
import { CustomTooltip, HtmlTooltip } from '../../../utils/CustomTooltip'
import { companyClickableIconsNameMap } from '../../../utils/constants'
import { capitalize, createSearchParams } from '../../../utils/utils'
import Loader from '../../common/Loader'
import { PropsFromReduxPatients } from '../containers/PatientsContainer'
import { Toolbar } from './Table/Toolbar'
import {
  canAddModifyDeletePatientData,
  canViewPatientData,
} from '../../auth/selectors/authSelector'
import { useLocation } from 'react-router-dom'

const getTabIndexByName = (name: string) => {
  switch (name) {
    case 'ACTIVE':
      return 0
    case 'TOCHECK':
      return 1
    case 'INWAINTING':
      return 2
    case 'ARCHIVED':
      return 3
  }
}

const PatientsComponent = (props: PropsFromReduxPatients) => {
  const location = useLocation()
  const q = useRef(new URLSearchParams(location.search)).current

  const [tab, setTab] = useState<0 | 1 | 2 | 3>(
    getTabIndexByName(q.get('tab') as string) || 0,
  )

  const [activePage, setActivePage] = useState(
    q.has('page') ? parseInt(q.get('page') as string) : 1,
  )
  const [rowsPerPage, setRowsPerPage] = useState(
    q.has('limit') ? parseInt(q.get('limit') as string) : 20,
  )
  const [isLoading, setIsLoading] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [selectedGammes, setSelectedGammes] = useState<string[]>(
    q.getAll('gamme[]') || [],
  )
  const [selectedMakerIds, setSelectedMakerIds] = useState<number[]>(
    (q.getAll('maker[]') as string[])?.map((m) => parseInt(m)) || [],
  )
  const [sortModel, setSortModel] = useState<GridSortModel | null>([])

  // Permissions check
  const hasPermissionToCreatePatient = useSelector(
    canAddModifyDeletePatientData,
  )
  const canViewPatientInfos = useSelector(canViewPatientData)

  const fetchDatas = async (loader: boolean) => {
    if (loader) setIsLoading(true)
    try {
      await props.getPatient(
        rowsPerPage,
        activePage - 1,
        getTabName(tab),
        searchText,
        selectedGammes,
        selectedMakerIds,
        sortModel?.length ? sortModel[0] : null,
      )
      await props.getPatientsSummary()
    } catch {
      toast.error('Impossible de charger les patients')
    } finally {
      setIsLoading(false)
    }
  }

  const fetchDisplay = async () => {
    setIsLoading(true)
    try {
      await props.getCountry()
      await props.getProsthesisGammeRange(rowsPerPage, activePage - 1)
      await props.getDoctorsSummary()
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchDisplay()
    props.getMakers()
  }, [])

  useEffect(() => {
    fetchDatas(true)
    const obj: { [key: string]: string | string[] } = {
      searchText,
      gamme: selectedGammes,
      maker: selectedMakerIds.map((s) => s.toString()),
      sortBy: (sortModel?.length ? sortModel[0].field : null) || '',
      sort: (sortModel?.length ? sortModel[0].sort : null) || '',
      page: activePage.toString(),
      limit: rowsPerPage.toString(),
      tab: getTabName(tab),
    }
    const params = createSearchParams(obj)
    const q = params.toString()
    if (history.location.search !== q) {
      history.push({
        search: q,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchText,
    selectedGammes,
    selectedMakerIds,
    sortModel,
    rowsPerPage,
    activePage,
    tab,
  ])

  useEffect(() => {
    const q = new URLSearchParams(location.search)
    const newTab = getTabIndexByName(q.get('tab') as string)
    if (newTab !== undefined && newTab !== tab) {
      setTab(newTab)
    }

    const newPage = q.has('page') ? parseInt(q.get('page') as string) : 1
    if (newPage !== activePage) {
      setActivePage(newPage)
    }

    const newLimit = q.has('limit') ? parseInt(q.get('limit') as string) : 20
    if (newLimit !== rowsPerPage) {
      setRowsPerPage(newLimit)
    }

    const newSearchText = q.get('searchText') || ''
    if (newSearchText !== searchText) {
      setSearchText(newSearchText)
    }

    const newSelectedGammes = q.getAll('gamme[]') || []
    if (newSelectedGammes.length !== selectedGammes.length) {
      setSelectedGammes(newSelectedGammes)
    }

    const newSelectedMakerIds = (q.getAll('maker[]') as string[])?.map((m) =>
      parseInt(m),
    )
    if (newSelectedMakerIds.length !== selectedMakerIds.length) {
      setSelectedMakerIds(newSelectedMakerIds)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  const getTabName = (newTab: 0 | 1 | 2 | 3) => {
    switch (newTab) {
      case 0:
        return 'ACTIVE'
      case 1:
        return 'TOCHECK'
      case 2:
        return 'INWAINTING'
      case 3:
        return 'ARCHIVED'
    }
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }

  const prosthesisGammeName = (value: string) => {
    switch (value) {
      case 'prosthesis.gamme.defib.cardiac':
        return 'Défibrillateur'
      case 'prosthesis.gamme.monit.implant':
        return 'Moniteur'
      case 'prosthesis.gamme.stim.cardiac':
        return 'Stimulateur'
      case 'prosthesis.gamme.null' || null:
        return 'Gamme non définie'
    }
  }

  const columns: GridColumns = [
    {
      field: 'id',
      width: 70,
      headerName: 'ID',
      sortable: true,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.id ? params.row.id : ''}`,
    },

    ...(canViewPatientInfos
      ? [
          {
            field: 'patientName',
            width: 175,
            headerName: 'Nom',
            sortable: true,
            renderCell: (params: GridRenderCellParams) => (
              <CustomTooltip
                text={
                  <p
                    style={{
                      margin: 0,
                      fontSize: '13px',
                      overflow: 'hidden',
                      textTransform: 'uppercase',
                    }}
                  >
                    {params.row.patientName ? params.row.patientName : ''}
                  </p>
                }
              >
                <Box>
                  {params.row.patientName ? params.row.patientName : ''}{' '}
                </Box>
              </CustomTooltip>
            ),
          },
          {
            field: 'firstName',
            width: 175,
            headerName: 'Prénom',
            sortable: true,
            renderCell: (params: GridRenderCellParams) => (
              <CustomTooltip
                text={
                  <p
                    style={{
                      margin: 0,
                      fontSize: '13px',
                      overflow: 'hidden',
                    }}
                  >
                    {params.row.firstName
                      ? capitalize(params.row.firstName.toLowerCase)
                      : ''}
                  </p>
                }
              >
                <Box>
                  {params.row.firstName
                    ? capitalize(params.row.firstName.toLowerCase())
                    : ''}
                </Box>
              </CustomTooltip>
            ),
          },
          {
            field: 'birthDay',
            width: 140,
            headerName: 'Date de naissance',
            sortable: true,
            valueGetter: (params: GridValueGetterParams) =>
              `${
                params.row.birthDay
                  ? params.row.birthDay
                    ? new Date(params.row.birthDay).toLocaleDateString()
                    : ''
                  : ''
              }`,
          },

          {
            field: 'col3',
            width: 250,
            headerName: 'Adresse',
            sortable: false,
            renderCell: (params: GridRenderCellParams) => (
              <CustomTooltip
                text={
                  <p
                    style={{ margin: 0, fontSize: '13px', overflow: 'hidden' }}
                  >
                    {params.row.address ? params.row.address : ''}{' '}
                    {params.row.zipCode ? params.row.zipCode : ''}{' '}
                    {params.row.city ? params.row.city : ''}
                  </p>
                }
              >
                <Box sx={{ margin: '20px 0' }}>
                  {params.row.address ? params.row.address : ''}{' '}
                  {params.row.zipCode ? params.row.zipCode : ''}{' '}
                  {params.row.city ? params.row.city : ''}
                </Box>
              </CustomTooltip>
            ),
          },
          {
            field: 'col4',
            width: 200,
            headerName: 'Téléphone',
            sortable: false,
            renderCell: (params: GridRenderCellParams) => (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  margin: '5px 0',
                }}
              >
                {params.row.phone ? <span>{params.row.phone}</span> : ''}
                {params.row.phone1 ? <span>{params.row.phone1}</span> : ''}
                {params.row.phone2 ? <span>{params.row.phone2}</span> : ''}
                {params.row.phone3 ? <span>{params.row.phone3}</span> : ''}
              </Box>
            ),
          },
          {
            field: 'col6',
            width: 150,
            headerName: 'Prothèse',
            sortable: false,
            valueGetter: (params: GridRenderCellParams) =>
              params.row.prosthesisGamme
                ? prosthesisGammeName(params.row.prosthesisGamme)
                : '',
          },
        ]
      : []),

    {
      field: 'implantDate',
      width: 150,
      headerName: "Date d'implantation",
      sortable: true,
      renderCell: (params) =>
        params.row.implantDate.length > 1 ? (
          <HtmlTooltip
            title={
              <div
                style={{
                  color: 'gray',
                  backgroundColor: 'white',
                  borderRadius: '8px',
                  padding: '8px',
                  border: '1px solid #CCC',
                  maxHeight: '400px',
                  overflowY: 'scroll',
                }}
              >
                <div
                  style={{
                    fontWeight: 'bold',
                  }}
                >
                  {params.row.implantDate?.map((date: Date) => (
                    <p
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {moment(date).format('DD-MM-YYYY')}{' '}
                    </p>
                  ))}
                </div>
              </div>
            }
          >
            <button
              style={{
                backgroundColor: 'white',
                width: '30px !important',
                border: 'none',
                display: 'flex',
                alignItems: 'center',
                padding: '4px 8px',
                borderRadius: '4px',
                cursor: 'pointer',
              }}
            >
              <BsFillCalendarEventFill
                style={{
                  color: '#3f3f3f',
                }}
              />
            </button>
          </HtmlTooltip>
        ) : params.row.implantDate ? (
          new Date(params.row.implantDate).toLocaleDateString()
        ) : (
          ''
        ),
      sortComparator: (v1, v2) => {
        const date1 = v1
          ? moment(v1, 'DD-MM-YYYY').toDate()
          : new Date('01-01-1990')

        const date2 = v2
          ? moment(v2, 'DD-MM-YYYY').toDate()
          : new Date('01-01-1990')

        const diff = date1.getTime() - date2.getTime()
        return diff
      },
    },
    {
      field: 'col7',
      width: 150,
      headerName: 'Marque',
      sortable: false,
      renderCell: (params) =>
        params.row.maker ? (
          companyClickableIconsNameMap[params.row.maker]
        ) : (
          <></>
        ),
    },
    {
      field: 'col8',
      width: 200,
      headerName: 'Modèle',
      sortable: false,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.model ? params.row.model : ''}`,
    },
    {
      field: 'col9',
      width: 150,
      headerName: 'N° de série',
      sortable: false,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.serialNumber ? params.row.serialNumber : ''}`,
    },
  ]

  return (
    <Box sx={{ height: 'calc(100vh - 220px)', width: '100%' }}>
      <Loader open={isLoading} text="Chargement des patients" />
      <Tabs
        TabIndicatorProps={{
          style: {
            backgroundColor: '#ffa726',
            display: 'flexWrap',
          },
        }}
        onChange={(_, newTab) => {
          setActivePage(1)
          setTab(newTab)
        }}
        textColor="primary"
        value={tab}
      >
        <Tab
          label={`Tous les patients : ${
            props.summary?.active ? props.summary.active : 0
          }`}
          {...a11yProps(0)}
          wrapped={false}
        />
        <Tab
          label={`Patients archivés : ${
            props.summary?.archived ? props.summary.archived : 0
          }`}
          {...a11yProps(0)}
          wrapped={false}
        />
      </Tabs>

      <DataGridPro
        rows={props.patients.data}
        columns={columns}
        onRowClick={
          canViewPatientInfos
            ? (params) => history.push(`/patients/${params.row.id}`)
            : undefined
        }
        components={{
          Toolbar,
        }}
        componentsProps={{
          toolbar: {
            title: 'Patients',
            postAddPatient: props.postAddPatient,
            getDoctors: props.getDoctors,
            addPatientCenter: props.addPatientCenter,
            doctorId: props.user?.id,
            canCreatePatient: hasPermissionToCreatePatient,
            searchText,
            setSearchText,
            getDoctorAutocomplete: props.getDoctorAutocomplete,
            getCountries: props.getCountries,
            addDoctor: props.addDoctor,
            selectedMakerIds,
            setSelectedMakerIds,
            selectedGammes,
            setSelectedGammes,
            makers: props.makers,
          },
        }}
        disableColumnMenu
        sortModel={sortModel || undefined}
        onSortModelChange={setSortModel}
        hideFooter
        sortingMode="server"
        disableSelectionOnClick
        sx={{
          border: 'none',
          margin: '3px 15px',
          cursor: 'pointer',
          borderBottom: '1px solid #E0E0E0',
        }}
      />
      <PaginationComponent
        pages={props.patients.totalPages}
        activePage={activePage}
        setActivePage={setActivePage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />
    </Box>
  )
}

export default PatientsComponent
