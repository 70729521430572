import { CircularProgress, IconButton } from '@mui/material'
import { CSSProperties, useEffect, useState } from 'react'
import { Document, Page } from 'react-pdf'
import CloseIcon from '@mui/icons-material/Close'
import { Link } from 'react-router-dom'

interface Props {
  getPDF?: () => Promise<Blob>
  onClose: () => void
  style: CSSProperties
  reportId?: number
  alertId?: number
}
const PdfPreview = ({ getPDF, style, onClose, reportId, alertId }: Props) => {
  const [pdf, setPdf] = useState<Blob | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [size, setSize] = useState({
    width: 0,
    height: 0,
  })

  useEffect(() => {
    if (getPDF) {
      setIsLoading(true)
      getPDF().then((blob: Blob) => {
        setPdf(blob)
        setIsLoading(false)
      })
    }
  }, [getPDF])
  useEffect(() => {
    // calculate height from width = 80% to respect a4 ratio
    const height = window.innerHeight * 0.8
    const width = (height * 210) / 297
    
    setSize({
      width,
      height,
    })
  }, [])

  return (
    <div style={style}>
      <div
        style={{
          display: 'flex',
          backgroundColor: 'white',
          height: '40px',
          flexDirection: 'row',
          justifyContent: 'flex-start',
        }}
      >
        <IconButton onClick={onClose} style={{ marginRight: '10px' }}>
          <CloseIcon />
        </IconButton>
      </div>
      {pdf && !isLoading && (
        <div>
          <Document file={window.URL.createObjectURL(pdf)}>
            <Page
              renderTextLayer={false}
              renderAnnotationLayer={false}
              key={`page_1`}
              className="pdf-page"
              pageNumber={1}
              width={size.width}
              height={size.height}
            />
          </Document>
        </div>
      )}
      {isLoading && (
        <div
          style={{
            width: '200px',
            height: '200px',
            backgroundColor: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </div>
      )}
      {reportId && alertId && (
          <Link
              to={{
                pathname: '/pdfviewer',
                state: {
                  reportId,
                  exportId: alertId,
                },
              }}
          >
            Voir le pdf
          </Link>
      )}
      
    </div>
  )
}

export default PdfPreview
