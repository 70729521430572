import {CORE_URL} from '../../../helpers/env'

export const GET_PATIENT = 'GET_PATIENT'
export const GET_PATIENT_SUCCESS = 'GET_PATIENT_SUCCESS'
export const GET_PATIENT_FAILED = 'GET_PATIENT_FAILED'

export const GET_PATIENTS_SUMMARY = 'GET_PATIENTS_SUMMARY'
export const GET_PATIENTS_SUMMARY_SUCCESS = 'GET_PATIENTS_SUMMARY_SUCCESS'
export const GET_PATIENTS_SUMMARY_FAILED = 'GET_PATIENTS_SUMMARY_FAILED'

export const GET_COUNTRY = 'GET_COUNTRY'
export const GET_COUNTRY_SUCCESS = 'GET_COUNTRY_SUCCESS'
export const GET_COUNTRY_FAILED = 'GET_COUNTRY_FAILED'

export const GET_PROSTHESIS_GAMME_RANGE = 'GET_PROSTHESIS_GAMME_RANGE'
export const GET_PROSTHESIS_GAMME_RANGE_SUCCESS = 'GET_PROSTHESIS_GAMME_RANGE_SUCCESS'
export const GET_PROSTHESIS_GAMME_RANGE_FAILED = 'GET_PROSTHESIS_GAMME_RANGE_FAILED'

export const POST_PATIENT = 'POST_PATIENT'
export const POST_PATIENT_SUCCESS = 'POST_PATIENT_SUCCESS'
export const POST_PATIENT_FAILED = 'POST_PATIENT_FAILED'

export const GET_PATIENT_BY_ID = 'GET_PATIENT_BY_ID'
export const GET_PATIENT_BY_ID_SUCCESS = 'GET_PATIENT_BY_ID_SUCCESS'
export const GET_PATIENT_BY_ID_FAILED = 'GET_PATIENT_BY_ID_FAILED'

export const UPDATE_PATIENT_INFO = 'UPDATE_PATIENT_INFO'
export const UPDATE_PATIENT_INFO_SUCCESS = 'UPDATE_PATIENT_INFO_SUCCESS'
export const UPDATE_PATIENT_INFO_FAILED = 'UPDATE_PATIENT_INFO_FAILED'

export const POST_NEW_NOTE = 'POST_NEW_NOTE'
export const POST_NEW_NOTE_SUCCESS = 'POST_NEW_NOTE_SUCCESS'
export const POST_NEW_NOTE_FAILED = 'POST_NEW_NOTE_FAILED'

export const POST_NEW_STATUS = 'POST_NEW_STATUS'
export const POST_NEW_STATUS_SUCCESS = 'POST_NEW_STATUS_SUCCESS'
export const POST_NEW_STATUS_FAILED = 'POST_NEW_STATUS_FAILED'

export const ADD_PATIENT_CENTER = 'ADD_PATIENT_CENTER'

export const ADD_PATIENT_IMPLANT = 'ADD_PATIENT_IMPLANT'

export const UPDATE_PATIENT_DOCTOR = 'UPDATE_PATIENT_DOCTOR'

export const UPDATE_PATIENT_HISTORY = 'UPDATE_PATIENT_HISTORY'

export const UPDATE_PATIENT_TREATMENT = 'UPDATE_PATIENT_TREATMENT'

export const UPDATE_IMPLANT = 'UPDATE_IMPLANT'

export const DELETE_IMPLANT = 'DELETE_IMPLANT'

export const API_URL = CORE_URL

export const UPDATE_PATIENT_REASONS = 'UPDATE_PATIENT_REASONS'

export const GET_TRANSMITTER_REFERENCES = 'GET_TRANSMITTER_REFERENCES'
export const GET_TRANSMITTER_REFERENCES_SUCCESS = 'GET_TRANSMITTER_REFERENCES_SUCCESS'

export const CREATE_TRANSMITTER = 'CREATE_TRANSMITTER'

export const AFFECT_TRANSMITTER = 'AFFECT_TRANSMITTER'

export const POST_AGREEMENT = 'POST_AGREEMENT'

export const DELETE_TRANSMITTER = 'DELETE_TRANSMITTER'