// ---------------- Production --------------
// export const CORE_URL = 'https://my-promantis.fr/rest'
// export const REFACTOR_URL = 'https://my-promantis.fr/rest-nest'
export const LICENCE_KEY =
  '77ad817c2c5c22742f27d01dee8ef10aTz02NzUyNixFPTE3MTcwNTUwODQyMjcsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
// export const STELLAIR_FSE = 'https://fse.stellair.fr'
//----------------- Staging ------------------
export const REFACTOR_URL = ' https://promantis-nest-api.ether.healthcare'
export const CORE_URL = 'https://promantis-java-api.ether.healthcare'
//--------------------------------------------

// export const REFACTOR_URL = ' http://localhost:8082'
export const STELLAIR_FSE = 'https://fse.demo.stellair.fr'
export const CLIENT_ID = '7ttbrneasa3v6hlv90qnnlhkh9'
// export const STELLAIR_AUTH_URL =
//   'https://auth.demo.stellair.fr/oauth2/authorize'
// export const CLIENT_ID = '12dqnd3ea64fngdc28hlaamoir'
export const VID_SIGNER_URL = 'https://pre-centralized.vidsigner.net/'
export const REACT_APP_OIDC_AUTHORIZATION_URI =
  'https://wallet.bas.psc.esante.gouv.fr'
export const REACT_APP_OIDC_REDIRECT_URI =
  'https://promantis-demo.ether.healthcare/login'
export const PRESCRIPTION_URL = 'http://localhost:3003'
export const REACT_APP_OIDC_PROVIDER_URI = 'https://auth.bas.psc.esante.gouv.fr'
export const REACT_APP_OIDC_CLIENT_ID = 'promantis-bas'
export const REACT_APP_OIDC_SECRET = 'XWmhqMTWULL9PCPwxsaC70yCDbE7IfvT'
